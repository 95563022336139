import { React, useState} from "react";
import { Link } from 'react-router-dom';

// MUI Imports
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import { useTheme } from '@mui/material/styles';

// Local Imports
import IconXSm from "../Images/Icon-xsml.png";


function Navbar() {
    const [openPlumbingMenu, setOpenPlumbingMenu] = useState(false)
    const [openPlumbingList, setOpenPlumbingList] = useState(false)
    const [openHvacMenu, setOpenHvacMenu] = useState(false)
    const [openHvacList, setOpenHvacList] = useState(false)
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElPlumbing, setAnchorElPlumnbing] = useState(null)
    const [anchorElHvac, setAnchorElHvac] = useState(null);
    const theme = useTheme()

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
        setOpenPlumbingList(false)
        setOpenHvacList(false)

    };
    
    const handlePlumbingMenu = (event) => {
        setOpenPlumbingMenu(!openPlumbingMenu)
        setAnchorElPlumnbing(event.currentTarget);
    };

    const handlePlumbingList = () => {
        setOpenPlumbingList(!openPlumbingList)
    };
    
    const handleHvacMenu = (event) => {
        setOpenHvacMenu(!openHvacMenu)
        setAnchorElHvac(event.currentTarget);
    };

    const handleHvacList = () => {
        setOpenHvacList(!openHvacList)
    };


    return (
        <AppBar position="sticky" sx={{ bgcolor: theme.palette.main.primary_darkest}}>
            <Container maxWidth="xl">
                <Box sx={{ display: 'flex', justifyContent: 'space-between'}}>
                    
                    {/* LOGO ICON */}
                    <Box sx={{ alignContent: 'center', my: 1}}>
                        <Link to="/">
                            <img className="IconXSm" src={IconXSm}  alt="Home" ></img>
                        </Link>
                    </Box>

                    {/* NAVBAR LINKS */}
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, ml: 4 }}>
                        <Button
                            onClick={handleCloseNavMenu}
                            sx={{ my: 2, color: 'white', display: 'block' }}
                        >
                            <Link onClick={handleCloseNavMenu} className="nav-link" aria-current="page" to="/">Home</Link>
                        </Button>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start'}}>
                            <Button
                                className="nav-link"
                                onClick={handlePlumbingMenu}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                Plumbing Services
                                {openPlumbingMenu ? <ExpandLess sx={{ color: 'white' }} /> : <ExpandMore sx={{ color: 'white'}}/>}
                                <Menu 
                                    id='plumbing-menu'
                                    anchorEl={anchorElPlumbing}
                                    open={openPlumbingMenu}
                                    onClose={handleCloseNavMenu}
                                >
                                    <Link onClick={handleCloseNavMenu} className="nav-link" aria-current="page" to="/plumbing-residential">
                                        <MenuItem sx={{ minWidth: 150}}> Residential</MenuItem>
                                    </Link>
                                    <Link onClick={handleCloseNavMenu} className="nav-link" aria-current="page" to="/plumbing-commercial">
                                        <MenuItem>Commercial</MenuItem>
                                    </Link>
                                </Menu>
                            </Button>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start'}}>
                            <Button
                                className="nav-link"
                                onClick={handleHvacMenu}
                                sx={{ my: 2, color: 'white', display: 'block'}}
                            >
                                HVAC Services
                                {openHvacMenu ? <ExpandLess sx={{ color: 'white' }} /> : <ExpandMore sx={{ color: 'white'}}/>}
                                <Menu 
                                    id='hvac-menu'
                                    anchorEl={anchorElHvac}
                                    open={openHvacMenu}
                                    onClose={handleCloseNavMenu}
                                >
                                    <Link onClick={handleCloseNavMenu} className="nav-link" aria-current="page" to="/hvac">
                                        <MenuItem>HVAC</MenuItem>
                                    </Link>
                                    <Link onClick={handleCloseNavMenu} className="nav-link" aria-current="page" to="/heating">
                                        <MenuItem>Heating</MenuItem>
                                    </Link>
                                    <Link onClick={handleCloseNavMenu} className="nav-link" aria-current="page" to="/air-conditioning">
                                        <MenuItem>Air Conditioning</MenuItem>
                                    </Link>
                                </Menu>
                            </Button>
                        </Box>
                        <Button
                            onClick={handleCloseNavMenu}
                            sx={{ my: 2, color: 'white', display: 'block' }}
                        >
                            <Link onClick={handleCloseNavMenu} className="nav-link" aria-current="page" to="/about">About</Link>
                        </Button>
                    </Box>
                    <Typography sx={{ alignContent: 'center' }}>
                        <a className='fw-bold fs-4' style={{color: "white"}} href='tel:2064767295'>206-476-7295</a> 
                    </Typography>

                    {/* HAMBURGER MENU */}
                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{ display: { xs: 'block', md: 'none' } }}
                        >
                            <MenuItem>
                                <ListItemButton onClick={handleCloseNavMenu}>
                                    <Link onClick={handleCloseNavMenu} className="nav-link" aria-current="page" to="/">Home</Link>
                                </ListItemButton>
                            </MenuItem>
                            <MenuItem>
                                <List>
                                    <ListItemButton
                                        className="nav-link"
                                        onClick={handlePlumbingList}
                                        sx={{ mr: -1 }}
                                    >
                                        Plumbing Services
                                        {openPlumbingList ? <ExpandLess sx={{ }} /> : <ExpandMore sx={{ }}/>}
                                    </ListItemButton>
                                    <Collapse in={openPlumbingList} timeout='auto' unmountOnExit>
                                        <Link onClick={handleCloseNavMenu} className="nav-link" aria-current="page" to="/plumbing-residential">
                                            <ListItemButton sx={{ pl: 3 }}>Residential</ListItemButton>
                                        </Link>
                                        <Link onClick={handleCloseNavMenu} className="nav-link" aria-current="page" to="/plumbing-commercial">
                                            <ListItemButton sx={{ pl: 3 }}>Commercial</ListItemButton>
                                        </Link>
                                    </Collapse>
                                </List>
                            </MenuItem>
                            <MenuItem>
                                <List>
                                    <ListItemButton
                                        className="nav-link"
                                        onClick={handleHvacList}
                                        sx={{ mr: -1 }}
                                    >
                                        HVAC Services
                                        {openHvacList ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemButton>
                                    <Collapse in={openHvacList} timeout='auto' unmountOnExit>
                                        <Link onClick={handleCloseNavMenu} className="nav-link" aria-current="page" to="/hvac">
                                            <ListItemButton sx={{ pl: 3 }}>HVAC</ListItemButton>
                                        </Link>
                                        <Link onClick={handleCloseNavMenu} className="nav-link" aria-current="page" to="/heating">
                                            <ListItemButton sx={{ pl: 3 }}>Heating</ListItemButton>
                                        </Link>
                                        <Link onClick={handleCloseNavMenu} className="nav-link" aria-current="page" to="/air-conditioning"> 
                                            <ListItemButton sx={{ pl: 3 }}>Air Conditioning</ListItemButton>
                                        </Link>
                                    </Collapse>
                                </List>
                            </MenuItem>
                            <MenuItem>
                                <ListItemButton onClick={handleCloseNavMenu}>
                                    <Link onClick={handleCloseNavMenu} className="nav-link" aria-current="page" to="/about">About</Link>
                                </ListItemButton>
                            </MenuItem>
                        </Menu>
                    </Box>
                    
                </Box>
            </Container>
        </AppBar>
    );
};

export default Navbar