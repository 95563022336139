import './About.css';
import agameFamily from "../Images/agame-family.png";

// MUI imports
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { useTheme } from '@emotion/react';

function About() {
    const theme = useTheme();

    return (
        <Box>
            <Container maxWidth='xl' disableGutters>
                <Box sx={{ 
                    backgroundImage: `url(${agameFamily})`
                    , backgroundRepeat: 'no-repeat'
                    , backgroundSize: "contain"
                    , backgroundPosition: 'center'
                    , height: {xs: '250px', md: '500px'}
                    , display: 'flex'
                    , justifyContent: 'center'
                    , alignItems: 'end'
                    , mt: {xs: 3, md: 4}
                    
                }}>
                    <Box sx={{ width: {xs: '90%', md: '40%'} }}>
                        <Card sx={{ bgcolor: theme.palette.main.primary_lightest, my: 2 }}>
                            <CardContent>
                                <Typography textAlign='center' 
                                    sx={{
                                        fontSize: {xs: '1em', md: '2em'},
                                        color: theme.palette.main.complementary_dark,
                                        fontWeight: 'bold',
                                    }}>ABOUT US
                                </Typography>
                            </CardContent>
                        </Card>
                      
                    </Box>
                </Box>
            </Container>
            <Container maxWidth='xl' sx={{ bgcolor: 'lightgray'}}>
                <Grid sx={{ display: 'flex', justifyContent: 'center', py: 3, my: 2, color: theme.palette.main.primary_darkest}} container >
                    <Grid item xs={12} md={5} sx={{ m: 2}}>
                        <Typography sx={{ fontSize: '1.2em', fontWeight: 'bold'}}>Our Mission</Typography>
                        <Typography>
                            Our mission is to make sure your home’s plumbing and HVAC systems 
                            are ready for when you need it. Our team is comprised of like-minded 
                            professionals who believe in bringing their “A-Game” day-in day-out.  
                            A-Game prides itself on being locally owned and operated, providing 
                            the community with the same service that we would provide to our families.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={5} sx={{ m: 2}}>
                        <Typography sx={{ fontSize: '1.2em', fontWeight: 'bold'}}>Our Promise</Typography>
                        <Typography>
                            Did a pipe burst and dump water all over? Is sewage backing up into your 
                            shower? Is your home freezing cold in the dead of winter or scorching hot 
                            in the peak of summer? Just give us a call – No matter what, A-Game got 
                            you covered.
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
            <Container maxWidth='xl' sx={{ bgcolor: theme.palette.main.complementary_darkest}}>
                <Grid sx={{ py: 3, color: theme.palette.main.primary_lightest, textAlign: {xs: 'center', md: 'left'}, alignContent: 'center'}} container spacing={2}>
                    <Grid item xs={12} md={2} sx={{ m: 2}}>
                        <Typography sx={{ fontSize: '2em', fontWeight: 'bold'}}>Motivation</Typography>
                    </Grid>
                    <Grid item xs={12} md={9} sx={{ m: 2}}>
                        <Typography>
                            At A-Game Plumbing and Heating, our motivation stems from a deep commitment to 
                            serving our community and enhancing the quality of life for our customers. We 
                            understand that plumbing and HVAC issues can disrupt daily routines and create 
                            stress, so we strive to provide swift, reliable solutions that restore comfort 
                            and peace of mind.
                            Our team is passionate about delivering high-quality workmanship and exceptional 
                            customer service, driven by the satisfaction of knowing we’ve made a positive 
                            difference. We believe in continuous improvement, staying up-to-date with the 
                            latest technologies and industry best practices to provide innovative and efficient 
                            solutions.
                            Moreover, we take pride in fostering lasting relationships with our customers, 
                            built on trust and transparency. Our motivation is not just about fixing pipes; 
                            it’s about building a reputation as a reliable partner in home and business 
                            maintenance. Ultimately, our goal is to ensure that every customer feels valued 
                            and secure in their choice of plumbing services.
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
            <Container maxWidth='xl' sx={{ bgcolor: 'lightgray'}}>
                <Grid sx={{ display: 'flex', justifyContent: 'center', py: 3, color: theme.palette.main.primary_darkest}} container >
                    <Grid item xs={12} md={3} sx={{ m: 2}}>
                        <Typography sx={{ fontSize: '1.2em', fontWeight: 'bold'}}>Trusted & Reliable Professionals</Typography>
                        <Typography>
                            We strive to exceed expectations by complete sewer line replacement, A-Game Plumbing 
                            & Heating offers a wide range of residential and commercial plumbing services to meet 
                            your needs. We continually strive to exceed expectations by ensuring that each client 
                            receives the very best service.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3} sx={{ m: 2}}>
                        <Typography sx={{ fontSize: '1.2em', fontWeight: 'bold'}}>Individualized Plumbing Options</Typography>
                        <Typography>
                            We strive to exceed expectations by ensuring that all plumbing work is completed to 
                            the highest standards. We will provide you with a consultation to explain all of your 
                            available options so that you can choose the scope of work that’s right for your home 
                            or business.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3} sx={{ m: 2}}>
                        <Typography sx={{ fontSize: '1.2em', fontWeight: 'bold'}}>Satisfaction Guaranteed</Typography>
                        <Typography>
                            We serve both residential and commercial clients and offer a variety of money-saving 
                            solutions. We combine our use of the highest quality parts and equipment, and our 
                            dedication to delivering exceptional work to provide you with satisfactory service.
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )

};

export default About
