import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import quoteIcon from "../../Images/quote-icon.svg";
import stars from "../../Images/5star.png";

export default function ErinM({stringAvatar}) {


    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', m: 2}}>
            <Box component="img"
                alt="quote"
                src={quoteIcon}
                sx={{ borderRadius: 3, py: {xs: 1, md: 2}, maxWidth: '70px'}}
            />
            <Box component="img"
                alt="five stars"
                src={stars}
                sx={{ mx: 'auto', borderRadius: 3, py: {xs: 1, md: 2}, maxWidth: '200px'}}
            />
            <Typography sx={{ minHeight: {xs: '264px', md: '144px'}}}>
                A-Game installed a bathtub for us and did great work! The quote came fast and 
                was reasonable and thorough, we got on the schedule two days later and the 
                work was done in a day. It was great working with them!
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 1}}>
                <Avatar {...stringAvatar('Erin M.')} />
            </Box>
            <Typography sx={{ textAlign: 'center'}}>Erin M.</Typography> 
        </Box>
    )
}