import './Heating.css';
import lancePaper from "../Images/lance-paper.png";


import { useEffect } from 'react';
import seo from '../Lib/Seo.js';

// MUI imports
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack'; 
import { useTheme } from '@emotion/react';

function Heating() {
    const theme = useTheme();

    useEffect(() => {
        seo.setMetaTitle("Heating Services in Seattle | Furnace Cleaning in Seattle");
        seo.setMetaDescription("It's time for a better level of quality in heating. Reach out to our team for expert heating services in Seattle. We'll make sure you're warm this winter.");

        return () => {
            seo.unsetCustomMetadata();
        };
    });

    return (
        <Box>
            <Container maxWidth='xl' disableGutters>
                <Box sx={{ 
                    backgroundImage: `url(${lancePaper})`
                    , backgroundRepeat: 'no-repeat'
                    , backgroundSize: "contain"
                    , height: {xs: '200px', md: '500px'}
                    , backgroundPosition: {xs: '5%', md: '25%'} 
                    , display: 'flex'
                    , justifyContent: {xs: 'right', md: 'center'}
                    , mt: {xs: 3, md: 4}
                    
                }}>
                    <Box sx={{ maxWidth: {xs: '50%', md: '40%'}, ml: {xs: 0, md: 50}, pt: 3, mr: 1}}>
                        <Card sx={{bgcolor: theme.palette.main.primary_lightest}}>
                            <CardContent>
                                <Typography gutterBottom textAlign='center'
                                    sx={{
                                        fontSize: {xs: '.95em', md: '2em'},
                                        color: theme.palette.main.complementary_dark,
                                        fontWeight: 'bold',
                                    }}>HEATING SERVICES IN SEATTLE
                                </Typography>
                            </CardContent>
                        </Card>
                      
                    </Box>
                </Box>
            </Container>

            <Container maxWidth='xl' sx={{ bgcolor: theme.palette.main.complementary_lightest}}>
                <Box sx={{ display: 'flex', pt: 3, justifyContent: 'center' }}>
                    <Card sx={{ bgcolor: theme.palette.main.primary_lightest, width: {xs: '100%', md: '50%'}, textAlign: 'center', mx: {xs: 2, md: 6}}}>
                        <Typography 
                            sx={{ 
                                color: theme.palette.main.complementary_dark, 
                                py: {xs: 1, md: 2},
                                fontSize: {xs: '1.2em', md: '2em'}, 
                                fontWeight: 'bold'
                            }}
                        >
                            HEATING SERVICES IN SEATTLE
                        </Typography>
                    </Card>
                </Box>
                <Box sx={{ py: 3, textAlign: 'center'}}>
                    <Stack spacing={1}>
                        <Typography >At A-Game Plumbing and Heating, we understand the importance of a reliable heating system, especially during Seattle's chilly and damp months. That's why we pride ourselves on offering top-notch heating services in Seattle tailored for both homeowners and businesses. Our team of experts dedicates themselves to ensuring your comfort and safety with efficient, high-quality furnace repair and cleaning solutions.</Typography>
                        <Typography >With our profound understanding of the greater Seattle area's unique climate, we're committed to delivering warmth and tranquility to your space. Choosing us means opting for peace of mind, knowing that your heating needs are handled with the utmost professionalism and care. Experience the difference of a reliable heating service in Seattle designed around you. Let A-Game Plumbing and Heating bring stellar warmth and comfort back into your life.</Typography>
                    </Stack>
                </Box>
            </Container>
        </Box>
    );
}

export default Heating;