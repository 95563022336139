import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import quoteIcon from "../../Images/quote-icon.svg";
import stars from "../../Images/5star.png";

export default function CindyC({stringAvatar}) {


    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', m: 2}}>
            <Box component="img"
                alt="quote"
                src={quoteIcon}
                sx={{ borderRadius: 3, py: {xs: 1, md: 2}, maxWidth: '70px'}}
            />
            <Box component="img"
                alt="five stars"
                src={stars}
                sx={{ mx: 'auto', borderRadius: 3, py: {xs: 1, md: 2}, maxWidth: '200px'}}
            />
            <Typography sx={{ minHeight: {xs: '264px', md: '144px'} }}>
                A-Game Plumbing is a lifesaver! 🙌🏽 We had a leaky faucet in our bathtub that was 
                incredibly annoying. At first, we thought it was something simple that we could fix 
                ourselves but it turned out to need a lot more work than expected. We called A-Game 
                and they were able to come the following day! They were thorough, knowledgeable, 
                and very professional. We’ll definitely be calling them for our future plumbing needs 
                and you should too!
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 1}}>
                <Avatar {...stringAvatar('Cindy C.')} />
            </Box>
            <Typography sx={{ textAlign: 'center'}}>Cindy C.</Typography> 
        </Box>
    )
}