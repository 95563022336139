import { Link } from 'react-router-dom';

import fb from "../Images/fb-icon.png";
import gmail from "../Images/gmail-icon.png";
import email from "../Images/email-icon.png";

import { Box, Stack, Button, Container, Typography } from "@mui/material";


function Footer() {

    return (
        <Container maxWidth='xl' sx={{ bgcolor: "#536879", py: 4}}>
            <Box sx={{ display: 'flex', flexDirection: {xs: 'column', md: 'row'}, p: 4, justifyContent: 'space-evenly'}}>
                <Box sx={{ width: {xs: '100%', md: '33%'}, textAlign: {xs: 'center', md: 'start'} }}>
                    <Stack spacing={2} >
                        <Typography sx={{ fontWeight: 'bold'}}>A-Game Plumbing and Heating</Typography>
                        <Typography sx={{ fontWeight: 'bold'}}>Open Monday-Friday 8AM–6PM.</Typography>
                        <Typography sx={{ fontWeight: 'bold'}}>Copyright 2024. All rights reserved.</Typography>
                    </Stack>
                </Box>
                <Box sx={{ width: {xs: '100%', md: '33%'}, py: {xs: 2, md: 0} }}>
                    <Stack>
                        <Button sx={{ color: 'white', fontSize: {xs: '.9em', md: '1em'}, mt: -1 }}>
                            <Link className="nav-link" to="/">Home</Link>
                        </Button>
                        <Button sx={{ color: 'white', fontSize: {xs: '.9em', md: '1em'} }}>
                            <Link className="nav-link" to="/plumbing-residential">Residential Plumbing Services</Link>
                        </Button>
                        <Button sx={{ color: 'white', fontSize: {xs: '.9em', md: '1em'} }}>
                            <Link className="nav-link" to="/plumbing-commercial">Commercial Plumbing Services</Link>
                        </Button>
                        <Button sx={{ color: 'white', fontSize: {xs: '.9em', md: '1em'} }}>
                            <Link className="nav-link" to="/hvac">HVAC Services</Link>
                        </Button>
                        <Button sx={{ color: 'white', fontSize: {xs: '.9em', md: '1em'} }}>
                            <Link className="nav-link" to="/about">About Us</Link>
                        </Button>
                    </Stack>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', width: {xs: '100%', md: '33%'} }}>
                    <Stack direction={"row"}>
                        <Link className="mx-1" to="https://www.facebook.com/AGamePros">
                            <img className='fb' src={fb} alt=''></img>
                        </Link>

                        <Link className="mx-1" to="https://goo.gl/maps/j2BNz3gsYQEEGbz46">
                            <img className='gmail' src={gmail} alt=''></img>
                        </Link>

                        <Link className="mx-1" to="mailto:agameplumbingandheating@gmail.com">
                            <img className='email' src={email} alt=''></img>
                        </Link>
                    </Stack>
                </Box>
            </Box>
        </Container> 
    )

};

export default Footer