import { Link } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import seo from '../Lib/Seo.js';
import ReCAPTCHA from 'react-google-recaptcha'


// image imports
import gpsIcon from "../Images/gps-icon.svg";
import expertIcon from "../Images/expert-icon.svg";
import protectIcon from "../Images/protect-icon.svg";
import starIcon from "../Images/star-icon.svg";
import danArmsCrossed from "../Images/dan-armscrossed.png";
import sinkMobile from "../Images/sink-mobile.png";
import sinkDesktop from "../Images/sink-desktop.png";
import teamCare from "../Images/team-care.png";
import coupon from "../Images/coupon.png";

// MUI imports
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack'; 
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTheme } from '@emotion/react';
import { keyframes } from '@mui/system';
import Divider from '@mui/material/Divider';
import CallIcon from '@mui/icons-material/Call';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';

// local imports
import MollyD from '../Components/Testimonials/MollyD.js';
import ErinM from '../Components/Testimonials/ErinM.js';
import DeanH from '../Components/Testimonials/DeanH.js';
import CindyC from '../Components/Testimonials/CindyC.js';


function stringToColor(string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
}
  
function stringAvatar(name) {
    return {
        sx: {
        bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

const blink = keyframes`
    from { opacity: 0; }
    to { opacity: 1; }
`;

function Home() {
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userMessage, setUserMessage] = useState('');
    const [quoteFormOpen, setQuoteFormOpen] = useState(false)
    const [hasSubmitted, setHasSubmitted] = useState('false');
    const [activeStep, setActiveStep] = useState(0);
    const [sllideDirection, setSlideDirection] = useState('left');
    const containerRef = useRef(null);
    const recaptcha = useRef()
    const theme = useTheme();
    const maxSteps = 4

    
    const handleClickOpen = () => {
        setQuoteFormOpen(true);
    };

    const handleClose = () => {
        setQuoteFormOpen(false);
    };
    
    const handleNext = () => {
        setSlideDirection('left')
        if (activeStep ===3) {
            setActiveStep(0)
        } else {
            setActiveStep( (prevActiveStep) => prevActiveStep + 1)
        }
    };

    const handleBack = () => {
        setSlideDirection('right')
        if (activeStep === 0) {
            setActiveStep(3)
        } else {
            setActiveStep( (prevActiveStep) => prevActiveStep - 1)
        }
    };

    let formSubmit = (event) => {
        event.preventDefault()
        const captchaValue = recaptcha.current.getValue()
        console.log('captchaValue', captchaValue)
        
        if (!captchaValue) {
            alert('Please verify the reCAPTCHA!')
        } else {
            setHasSubmitted('loading')
            fetch('https://4p9s7bfzxl.execute-api.us-west-2.amazonaws.com/sendemail', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
        
                    },
                    body: JSON.stringify({
                        name: userName,
                        email: userEmail,
                        message: userMessage
                    })
            }).then( (response) => {
                if (response.status === 200) {
                    setHasSubmitted('true')
                } else {
                    setHasSubmitted('error')
                }
                recaptcha.current.reset();
            } )
        }
           
    }
    useEffect(() => {
        seo.setMetaTitle("Plumbing and HVAC Services in the Seattle Area");
        seo.setMetaDescription("When your home or business needs plumbing and HVAC services in the Seattle area, you call A-Game. We're here to help you every step of the way.");

        return () => {
            seo.unsetCustomMetadata();
        };
    });

    return (

        <Box>
            {/* HERO IMAGE */}
            <Container maxWidth='xl'>
                <Box sx={{ 
                        backgroundImage: `url(${danArmsCrossed})`
                        , backgroundRepeat: 'no-repeat'
                        , backgroundSize: "contain"
                        , mt: 3
                        , height: {xs: '300px', md: '500px'}
                        , backgroundPosition: {xs: '5%', md: '25%'} 
                        , display: 'flex'
                        , justifyContent: {xs: 'right', md: 'center'} 
                        
                    }}>
                   
                    <Box sx={{ maxWidth: {xs: '50%', md: '40%'}, ml: {xs: 0, md: 50}}}>
                        <Card sx={{bgcolor: theme.palette.main.primary_lightest}}>
                            <CardContent>
                                <Typography gutterBottom textAlign='center'
                                    sx={{
                                        fontSize: {xs: '.95em', md: '2em'},
                                        color: theme.palette.main.complementary_dark,
                                        fontWeight: 'bold',
                                    }}>A-GAME PLUMBING & HEATING
                                </Typography>
                                <Typography textAlign='center' gutterBottom sx={{ fontSize: {xs: '1em', md: '1.5em'}}}>
                                    Local plumbers with over 50 years of experience.
                                </Typography>
                                <Divider sx={{my: {xs: 1, md: 2}, bgcolor: theme.palette.main.primary_darkest }}/>
                                <Typography  textAlign={'center'}
                                    sx={{ 
                                        fontSize: {xs: '0em', md: '1.5em'}
                                        , color: theme.palette.main.primary_darkest
                                        , fontWeight: 'bold'
                                    }}
                                >
                                    Your comfort, our A-Game. Make the winning call!
                                </Typography>
                            </CardContent>
                            <CardActions sx={{display: 'flex', justifyContent: 'center', mb: 1}}>
                                <Button href='tel:2064767295' variant="contained"
                                     sx={{ 
                                        bgcolor: theme.palette.main.complementary_dark, fontWeight: 'bold'
                                        , ':hover': {
                                            bgcolor: theme.palette.main.complementary_darkest, // theme.palette.primary.main
                                            color: 'white',
                                          },
                                     }}
                                >
                                    <CallIcon sx={{ mr: 1, animation: `${blink} 1s linear infinite`,}} fontSize='small'/> 206-476-7295
                                </Button>
                            </CardActions>
                        </Card>
                      
                    </Box>
                </Box>
            </Container>
                        
            <Container  maxWidth='xl' sx={{ bgcolor: theme.palette.main.complementary_lightest}}>
                <Box sx={{ display: 'flex', justifyContent: 'center', py: 4}}>
                    <Button sx={{ color: theme.palette.main.primary_darkest}} onClick={handleClickOpen}>
                        <Typography sx={{ fontSize: {xs: '1.2em', md: '1.5em'}, fontWeight: 'bold'}}>
                            Request a free quote today! 
                            <ArrowForwardIcon fontSize='large' sx={{ ml: 1, color: theme.palette.main.complementary_dark}}/>
                        </Typography>
                    </Button>
                </Box>
            </Container>
            <Dialog
                open={quoteFormOpen}
                onClose={handleClose}
                fullWidth
            >
                <DialogTitle>Free Quote</DialogTitle>
                <DialogContent>
                    <Typography>
                       Call or text is quickest!
                    </Typography>
                    <Button href='tel:2064767295' variant="contained"
                            sx={{ 
                            bgcolor: theme.palette.main.complementary_dark, fontWeight: 'bold'
                            , ':hover': {
                                bgcolor: theme.palette.main.complementary_darkest,
                                color: 'white',
                                },
                            }}
                    >
                        <CallIcon sx={{ mr: 1}} fontSize='small'/> 206-476-7295
                    </Button>
                    <Typography sx={{py: 2}}>OR</Typography>
                    { 
                        hasSubmitted === 'true' ?
                            <Box >
                                <Typography variant='h6' sx={{ color: 'green'}}><CheckCircleOutlineIcon fontSize='large'/>Message sent!</Typography>
                                <Typography>We typically respond within 24 hours.</Typography> 
                            </Box>
                        : hasSubmitted === 'false' ?
                            <Box>
                                <Typography>Complete the form below</Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'column'}}>
                                    <form onSubmit={formSubmit} autoComplete="off" style={{backgroundColor: theme.palette.main.primary_light, borderRadius: 5}}>
                                        <Stack spacing={2} direction='column' sx={{ m: 3}}>
                                            <TextField 
                                                type="text"
                                                variant='filled'
                                                id="nameInput"
                                                label="Name"
                                                onChange={(event) => {setUserName(event.target.value);}}
                                                value={userName} 
                                                fullWidth
                                                required
                                            />
                                            <TextField 
                                                type="email"
                                                variant='filled'
                                                id="emailInput"
                                                label="Email"
                                                onChange={(event) => {setUserEmail(event.target.value);}}
                                                value={userEmail} 
                                                fullWidth
                                                required
                                            />
                                            <TextField 
                                                type="text"
                                                variant='filled'
                                                id="messageInput" 
                                                label="Message"
                                                onChange={(event) => {setUserMessage(event.target.value);}}
                                                value={userMessage}
                                                fullWidth
                                                required
                                                multiline
                                                rows="3"
                                            />
                                        </Stack>
                                        <Box sx={{ display: 'flex', justifyContent: 'center', py:2 }}>
                                            <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY} />
                                        </Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'center'}}>
                                            <Button variant='contained' 
                                                sx={{bgcolor: theme.palette.main.complementary_dark, ':hover': {bgcolor: theme.palette.main.complementary_darkest}, m: 'auto' }} 
                                                type='submit'
                                            >
                                                Submit
                                            </Button>
                                        </Box>
                                    </form>
                                </Box>
                            </Box>
                        : hasSubmitted === 'loading' ?
                            <Box sx={{ display: 'flex', justifyContent: 'center', py: 3}}>
                                <CircularProgress />
                            </Box>
                        :
                            <Box >
                                <Typography variant='h6' sx={{ color: 'orange'}}><WarningAmberIcon fontSize='large' />Uh no, an error occured during submission.</Typography>
                                <Typography>We're looking into it. In the meantime, please feel free to email us your inquiry at lance@agameplumbing.com</Typography> 
                            </Box>
                    }
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' 
                        sx={{ bgcolor: 'gray', ':hover': {bgcolor: '#63666A'} }} 
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <Container maxWidth='xl' sx={{ 
                backgroundImage: {xs: `url(${sinkMobile})`, md: `url(${sinkDesktop})` }
                , backgroundRepeat: 'no-repeat'
                , backgroundSize: "cover"
                }}
            >
                <Container maxWidth='xl' >
                    <Box sx={{ display: 'flex', pt: 3, justifyContent: 'center' }}>
                        <Card sx={{ bgcolor: theme.palette.main.primary_lightest, width: {xs: '100%', md: '50%'}, textAlign: 'center', mx: {xs: 2, md: 6}}}>
                            <Typography 
                                sx={{ 
                                    color: theme.palette.main.complementary_dark, 
                                    py: {xs: 1, md: 2},
                                    fontSize: {xs: '1.2em', md: '2em'}, 
                                    fontWeight: 'bold'
                                }}
                            >
                                WE BRING OUR A-GAME
                            </Typography>
                        </Card>
                    </Box>
                    <Box sx={{ display: 'flex', pt: 3, flexDirection: {xs: 'column', md: 'row'}, justifyContent: 'center' }}>
                        <Card sx={{ width: {xs: 'inherit', md: '30%'}, height: 'auto', alignItems: 'center', mx: {xs: 2, md: 6}, my: 2, py: 3}}>  
                            <Stack spacing={1} textAlign={'center'} sx={{ m: 2}}>
                                <Box component="img"
                                    alt="locally owned"
                                    src={gpsIcon}
                                    sx={{ height: "50px", width: "auto" }}
                                />
                                <Typography variant='h6'>Locally Owned.</Typography>
                                <Typography>
                                    Many of the A-Game team were born and raised in the Pacific Northwest. 
                                    We proudly provide plumbing and HVAC services to our childhood communities
                                    and throughout the greater Seattle area, including North Seattle, the East Side, and even 
                                    Snohomish County.
                                </Typography>
                            </Stack>
                        </Card>
                        <Card sx={{ width: {xs: 'inherit', md: '30%'}, height: 'auto', alignItems: 'center', mx: {xs: 2, md: 6}, my: 2, py: 3}}>
                            <Stack spacing={1} textAlign={'center'} sx={{ m: 2}}>
                                <Box component="img"
                                    alt="50+ exp"
                                    src={expertIcon}
                                    sx={{ height: "50px", width: "auto" }}
                                />
                                <Typography variant='h6'>50+ Years Experience.</Typography>
                                <Typography>
                                    As installers, we have a combined 50+ years of industry experience working in 
                                    residential and commercial settings. We understand plumbing can be stressful 
                                    and we do our very best to relieve that burden with our great service, 
                                    knowledge and experience, and most importantly, our trustworthy technicians.
                                </Typography>
                            </Stack>
                        </Card>
                    </Box>
                </Container>
                <Container maxWidth='xl' >
                    <Box sx={{ display: 'flex', pb: 3, flexDirection: {xs: 'column', md: 'row'}, justifyContent: 'center' }}>
                        <Card sx={{ width: {xs: 'inherit', md: '30%'}, alignItems: 'center', mx: {xs: 2, md: 6}, my:2, py: 3}}>  
                            <Stack spacing={1} textAlign={'center'} sx={{ m: 2}}>
                                <Box component="img"
                                    alt="highly rated"
                                    src={starIcon}
                                    sx={{ height: "55px", width: "auto" }}
                                />
                                <Typography variant='h6'>Highly Rated.</Typography>
                                <Typography>
                                    We know ratings aren't everything, but there is nothing like the sense of satisfaction, 
                                    motivation, and accomplisment when we hear the positive feedback from our customers. It's 
                                    an honor to serve such amazing communities. 
                                </Typography>
                            </Stack>
                        </Card>
                        <Card sx={{ width: {xs: 'inherit', md: '30%'}, alignItems: 'center', mx: {xs: 2, md: 6}, my:2, py: 3}}>
                            <Stack spacing={1} textAlign={'center'} sx={{ m: 2}}>
                                <Box component="img"
                                    alt="licensed, bonded, insured"
                                    src={protectIcon}
                                    sx={{ height: "50px", width: "auto" }}
                                />
                                <Typography variant='h6'>Licensed. Bonded. Insured.</Typography>
                                <Typography>
                                    Plumbing and heating projects can get complicated in a hurry. There are important 
                                    factors to know and to consider in order to protect yourself and your home, especially 
                                    in Washington State. A-Game technicians know all the relevant policies and 
                                    have all the appropraite licenses to protect our customers on every project.
                                </Typography>
                            </Stack>
                        </Card>
                    </Box>
                </Container>
            </Container>
            <Container maxWidth='xl'>
                <Box sx={{ display: 'flex', pt: 3, justifyContent: 'center' }}>
                    <Card sx={{ bgcolor: theme.palette.main.primary_lightest, width: {xs: '100%', md: '50%'}, textAlign: 'center', mx: {xs: 2, md: 6}}}>
                        <Typography 
                            sx={{ 
                                color: theme.palette.main.complementary_dark, 
                                py: {xs: 1, md: 2},
                                fontSize: {xs: '1.2em', md: '2em'}, 
                                fontWeight: 'bold'
                            }}
                        >
                            WE CARE
                        </Typography>
                    </Card>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: {xs: 'column', md: 'row'}, mx: {xs: 2, md: 6}, py: 3}}>
                    <Stack sx={{ mr: 2}}>
                        <Typography gutterBottom
                            sx={{ 
                                // color: 'theme.palette.main.primary_darkest', 
                                py: {xs: 1, md: 2},
                                fontSize: {xs: '1em', md: '1.5em'},
                            }}
                        >
                            Because you're not just getting top-notch plumbing and HVAC services, you're 
                            getting partners dedicated to keeping your space running smoothly.
                        </Typography>
                        <Box component="img"
                            alt="weCare"
                            src={teamCare}
                            sx={{ display: {xs: 'auto', md: 'none'}, height: "auto", width: "100%", borderRadius: 1, py: {xs: 1, md: 2} }}
                        />
                        <Typography gutterBottom
                            sx={{ 
                                color: theme.palette.main.primary_darkest, 
                                pt: {xs: 1, md: 2},
                                fontSize: {xs: '1em', md: '1.5em'},
                                fontWeight: 'bold',
                                textDecoration: 'underline',
                                textDecorationColor: theme.palette.main.complementary_dark
                            }}
                        >
                            Customer
                        </Typography>
                        <Typography gutterBottom
                            sx={{ 
                                color: theme.palette.main.primary_darkest, 
                                fontSize: {xs: '1em', md: '1.2em'},
                            }}
                        >
                            At A-Game Plumbing and Heating, we genuinely care about our customers. 
                            Your satisfaction is our top priority, and we're committed to providing 
                            exceptional service and support. Thank you for trusting us—we're here to 
                            ensure you have the best experience possible!
                        </Typography>
                        <Typography gutterBottom
                            sx={{ 
                                color: theme.palette.main.primary_darkest, 
                                pt: {xs: 1, md: 2},
                                fontSize: {xs: '1em', md: '1.5em'},
                                fontWeight: 'bold',
                                textDecoration: 'underline',
                                textDecorationColor: theme.palette.main.complementary_dark
                            }}
                        >
                            Assistance  
                        </Typography>
                        <Typography gutterBottom
                            sx={{ 
                                color: theme.palette.main.primary_darkest, 
                                fontSize: {xs: '1em', md: '1.2em'},
                            }}
                        >
                            We’re here to provide you with the assistance you need! If you have any 
                            questions about our services, need troubleshooting help, or require 
                            guidance on your next steps, our team is ready to support you. Your 
                            satisfaction is important to us, so please reach out anytime—we’re always 
                            happy to help!
                        </Typography>
                        <Typography gutterBottom
                            sx={{ 
                                color: theme.palette.main.primary_darkest, 
                                pt: {xs: 1, md: 2},
                                fontSize: {xs: '1em', md: '1.5em'},
                                fontWeight: 'bold',
                                textDecoration: 'underline',
                                textDecorationColor: theme.palette.main.complementary_dark
                            }}
                        >
                            Response
                        </Typography>
                        <Typography gutterBottom
                            sx={{ 
                                color: theme.palette.main.primary_darkest, 
                                fontSize: {xs: '1em', md: '1.2em'},
                            }}
                        >
                            We pride ourselves on being responsive to your needs! Whether you reach 
                            out via phone, email, or chat, our team is dedicated to providing timely 
                            support and answers. Your inquiries are important to us, and we’re here 
                            to ensure you receive prompt assistance every time!
                        </Typography>
                        <Typography gutterBottom
                            sx={{ 
                                color: theme.palette.main.primary_darkest, 
                                pt: {xs: 1, md: 2},
                                fontSize: {xs: '1em', md: '1.5em'},
                                fontWeight: 'bold',
                                textDecoration: 'underline',
                                textDecorationColor: theme.palette.main.complementary_dark
                            }}
                        >
                            Expertise
                        </Typography>
                        <Typography gutterBottom
                            sx={{ 
                                color: theme.palette.main.primary_darkest, 
                                fontSize: {xs: '1em', md: '1.2em'},
                            }}
                        >
                            Whether it’s troubleshooting, maintenance, or innovative solutions, our 
                            commitment to excellence means you get to relax knowing A-Game is working 
                            for you. We take pride in our ability to understand your requirements and 
                            offer insights that help you make informed decisions. Partner with us and 
                            experience the confidence that comes from working with true experts in 
                            the field!
                        </Typography>
                    </Stack>
                    <Box component="img"
                        alt="weCare"
                        src={teamCare}
                        sx={{ display: {xs: 'none', md: 'flex'}, height: "30%", width: {xs: "100%", md: '30%'}, borderRadius: 1, py: {xs: 1, md: 2} }}
                    />
                </Box>
            </Container>
            <Container maxWidth='xl' sx={{bgcolor: theme.palette.main.primary_darkest}}>
                <Box sx={{ display: 'flex', pt: 3, justifyContent: 'center' }}>
                    <Card sx={{ bgcolor: theme.palette.main.primary_lightest, width: {xs: '100%', md: '50%'}, textAlign: 'center', mx: {xs: 2, md: 6}}}>
                        <Typography 
                            sx={{ 
                                color: theme.palette.main.complementary_dark, 
                                py: {xs: 1, md: 2},
                                fontSize: {xs: '1.2em', md: '2em'}, 
                                fontWeight: 'bold'
                            }}
                        >
                            SEASONAL PROMOTIONS
                        </Typography>
                    </Card>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center'}}>
                    <Box component="img"
                        alt="coupon"
                        src={coupon}
                        sx={{ borderRadius: 3, py: {xs: 1, md: 2}, maxWidth: '100%', maxHeight: '550px'}}
                    />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center'}}>
                    <Button variant='contained' sx={{ bgcolor: theme.palette.main.analogous_darkest, my:2}}>
                        <Link className="nav-link" aria-current="page" to="/plumbing-residential">explore plumbing services</Link>
                    </Button>

                </Box>
            </Container>
            <Container maxWidth='xl' sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                <Box sx={{ display: 'flex', pt: 3, justifyContent: 'center' }}>
                    <Card sx={{ bgcolor: theme.palette.main.primary_lightest, width: {xs: '100%', md: '50%'}, textAlign: 'center', mx: {xs: 2, md: 6}}}>
                        <Typography 
                            sx={{ 
                                color: theme.palette.main.complementary_dark, 
                                py: {xs: 1, md: 2},
                                fontSize: {xs: '1.2em', md: '2em'}, 
                                fontWeight: 'bold'
                            }}
                        >
                            CUSTOMER REVIEWS
                        </Typography>
                    </Card>
                </Box>
                <Paper sx={{
                    my: 3,
                    mx: 'auto',
                    maxWidth: {xs: '100%', md: '50%'}

                }}>
                    <Box ref={containerRef} sx={{
                        display: 'flex'
                        , flexDirection: 'column'
                        , flexGrow: 1
                        , width: '100%'
                        , height: '100%'
                        , justifyContent: 'center'
                        , overflow: 'hidden'
                    }}>
                        {[MollyD, ErinM, DeanH, CindyC].map( (Component, idx) => (
                            <Slide key={idx} 
                                direction={sllideDirection} 
                                in={activeStep===idx} 
                                container={containerRef.current}
                            >
                                <Box sx={{
                                    display: activeStep === idx ? 'bloxk' : 'none'
                                    , justifyContent: 'center'
                                    , width: '100%'
                                    , height: '100%'
                                }}>
                                    <Component stringAvatar={stringAvatar}/>
                                </Box>
                            </Slide>
                        ))}

                        <MobileStepper
                            variant='dots'
                            steps={maxSteps}
                            position='static'
                            activeStep={activeStep}
                            nextButton={
                                <Button onClick={handleNext}>
                                    {theme.direction === 'rtl' ? (
                                        <KeyboardArrowLeft />
                                    ) : (
                                        <KeyboardArrowRight />
                                    )}
                                </Button>
                            }
                            backButton={
                                <Button onClick={handleBack}>
                                    {theme.direction === 'rtl' ? (
                                        <KeyboardArrowRight />
                                    ) : (
                                        <KeyboardArrowLeft />
                                    )}
                                </Button>
                            }
                        >
                        </MobileStepper>

                    </Box>

                </Paper>
            </Container>

        </Box>
    )
};

export default Home