import './PlumbingResidential.css';
import waterDist from "../Images/water-dist-icon.svg";
import drainage from "../Images/drainage-icon.svg";
import waterHeater from "../Images/water-heater-icon.svg";
import sewer from "../Images/sewer-icon.svg";
import bigWrench from "../Images/big-wrench.png";

import { useEffect } from 'react';
import seo from '../Lib/Seo.js';

// MUI imports
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack'; 
import Grid from '@mui/material/Grid';


import { useTheme } from '@emotion/react';

function PlumbingResendential() {
    const theme = useTheme();

    useEffect(() => {
        seo.setMetaTitle("Residential Plumbing Services in Seattle | A-Game");
        seo.setMetaDescription("Residential plumbing services in Seattle are something that no homeowner can go without. When you're in need of plumbing assistance, reach out to A-Game.");

        return () => {
            seo.unsetCustomMetadata();
        };
    });

    return (
        <Box>
            <Container maxWidth='xl' disableGutters>
                <Box sx={{ 
                    backgroundImage: `url(${bigWrench})`
                    , backgroundRepeat: 'no-repeat'
                    , backgroundSize: "contain"
                    , height: {xs: '200px', md: '500px'}
                    , backgroundPosition: {xs: '5%', md: '25%'} 
                    , display: 'flex'
                    , justifyContent: {xs: 'right', md: 'center'}
                    , mt: {xs: 3, md: 4}
                    
                }}>
                    <Box sx={{ maxWidth: {xs: '50%', md: '40%'}, ml: {xs: 0, md: 50}, pt: 3, mr: 1}}>
                        <Card sx={{bgcolor: theme.palette.main.primary_lightest}}>
                            <CardContent>
                                <Typography gutterBottom textAlign='center'
                                    sx={{
                                        fontSize: {xs: '.95em', md: '2em'},
                                        color: theme.palette.main.complementary_dark,
                                        fontWeight: 'bold',
                                    }}>RESIDENTIAL PLUMBING
                                </Typography>
                                <Typography textAlign='center' gutterBottom sx={{ fontSize: {xs: '0em', md: '1.5em'}}}>
                                    Local plumbers with over 50 years of experience.
                                </Typography>
                            </CardContent>
                        </Card>
                      
                    </Box>
                </Box>
            </Container>
            
            <Container maxWidth='xl' sx={{ bgcolor: theme.palette.main.complementary_lightest}}>
                <Box sx={{ display: 'flex', pt: 3, justifyContent: 'center' }}>
                    <Card sx={{ bgcolor: theme.palette.main.primary_lightest, width: {xs: '100%', md: '50%'}, textAlign: 'center', mx: {xs: 2, md: 6}}}>
                        <Typography 
                            sx={{ 
                                color: theme.palette.main.complementary_dark, 
                                py: {xs: 1, md: 2},
                                fontSize: {xs: '1.2em', md: '2em'}, 
                                fontWeight: 'bold'
                            }}
                        >
                            PLUMBING DONE BY THE PROS
                        </Typography>
                    </Card>
                </Box>
                <Box sx={{ py: 3, textAlign: 'center'}}>
                    <Stack spacing={1}>
                        <Typography >Experience unparalleled residential plumbing services in Seattle with A-Game Plumbing and Heating. As local homeowners ourselves, we understand the importance of reliable plumbing solutions tailored to the unique needs of Seattle residents. Our team of certified professionals is dedicated to delivering exceptional workmanship and the highest level of customer satisfaction.</Typography>
                        <Typography >Whether it's routine maintenance, sewer system repairs, or full-scale installations, we bring our A-game to ensure your home's plumbing and heating systems operate flawlessly. We can even repair or replace hot water heaters if needed. With our proactive approach and meticulous attention to detail, we not only solve your current issues but also safeguard your home against future problems. Choose A-Game Plumbing and Heating—where we're proud to offer the best residential plumbing services in Seattle.</Typography>
                    </Stack>
                </Box>
            </Container>

            <Grid container spacing={2} sx={{my: 2, textAlign: 'center'}}>
                <Grid item xs={12} md={3}>
                    <Card sx={{backgroundImage: "linear-gradient(#00467F, #1382C7)", height: '100%', color: 'white', px: 2}}>
                        <Box className='row justify-content-center py-5'>
                            <img className="waterDist " src={waterDist} style={{height: '70px'}} alt=""></img>
                        </Box>
                        <Box className='row pb-5'>
                            <Typography variant='h5' fontWeight={'bold'}>Water Distribution</Typography>
                            <Typography variant='body2'>We are able to service and install all components required for the proper operation of your water distribution system including: Valves, Piping, Shower Systems, Water Heaters, Faucets and other plumbing related appliances.</Typography>
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Card sx={{backgroundImage: "linear-gradient(#00467F, #1382C7)", height: '100%', color: 'white', px: 2}}>
                        <Box className='row justify-content-center py-5'>
                            <img className="drainage" src={drainage} style={{height: '70px'}} alt=""></img>
                        </Box>
                        <Box className='row pb-5'>
                            <Typography variant='h5' fontWeight={'bold'}>Sanitary Drainage</Typography>
                            <Typography variant='body2'>We can assist you in the installation and maintenance of your home's drainage system. This includes: under sink tubular and garbage disposal, main trunk lines and branch drains, and even adding a new bathroom entirely!</Typography>
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Card sx={{backgroundImage: "linear-gradient(#00467F, #1382C7)", height: '100%', color: 'white', px: 2}}>
                        <Box className='row justify-content-center py-5'>
                            <img className="waterHeater" src={waterHeater} style={{height: '70px'}} alt=""></img>
                        </Box>
                        <Box className='row pb-5'>
                            <Typography variant='h5' fontWeight={'bold'}>Water Heaters</Typography>
                            <Typography variant='body2'>We are well-versed in the installation and maintenance of Water Heating Systems. We can assess the needs of your home and repair or replace all residential water heaters whether it be a tank, tankless or heat pump water heater.</Typography>
                        </Box>
                
                    </Card>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Card sx={{backgroundImage: "linear-gradient(#00467F, #1382C7)", height: '100%', color: 'white', px: 2}}>
                        <Box className='row justify-content-center py-5'>
                            <img className="sewer" src={sewer} style={{height: '70px'}} alt=""></img>
                        </Box>
                        <Box className='row pb-5'>
                            <Typography variant='h5' fontWeight={'bold'}>Sewer Services</Typography>
                            <Typography variant='body2'>We have the ability to assist you in the maintenance or replacement of your homes sewer system, and can provide you with drain cleaning, scope imaging, and trenched or trenchless repair options.</Typography>
                        </Box>
                    </Card>
                </Grid>
            </Grid>

        </Box>


    )

};

export default PlumbingResendential