import './HVAC.css';
import toolbox from "../Images/toolbox-icon.svg";
import retrofit from "../Images/retrofit-icon.svg";
import fan from "../Images/fan-icon.svg";
import lancePaper from "../Images/lance-paper.png";

// MUI imports
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack'; 
import Grid from '@mui/material/Grid';
import { useTheme } from '@emotion/react';

function HVAC() {
    const theme = useTheme();

    return (
        <Box>
            <Container maxWidth='xl' disableGutters>
                <Box sx={{ 
                    backgroundImage: `url(${lancePaper})`
                    , backgroundRepeat: 'no-repeat'
                    , backgroundSize: "contain"
                    , height: {xs: '200px', md: '500px'}
                    , backgroundPosition: {xs: '5%', md: '25%'} 
                    , display: 'flex'
                    , justifyContent: {xs: 'right', md: 'center'}
                    , mt: {xs: 3, md: 4}
                    
                }}>
                    <Box sx={{ maxWidth: {xs: '50%', md: '40%'}, ml: {xs: 0, md: 50}, pt: 3, mr: 1}}>
                        <Card sx={{bgcolor: theme.palette.main.primary_lightest}}>
                            <CardContent>
                                <Typography gutterBottom textAlign='center'
                                    sx={{
                                        fontSize: {xs: '.95em', md: '2em'},
                                        color: theme.palette.main.complementary_dark,
                                        fontWeight: 'bold',
                                    }}>HEATING, VENTILATION, AND AIR CONDITIONING (HVAC)
                                </Typography>
                            </CardContent>
                        </Card>
                      
                    </Box>
                </Box>
            </Container>

            <Container maxWidth='xl' sx={{ bgcolor: theme.palette.main.complementary_lightest}}>
                <Box sx={{ display: 'flex', pt: 3, justifyContent: 'center' }}>
                    <Card sx={{ bgcolor: theme.palette.main.primary_lightest, width: {xs: '100%', md: '50%'}, textAlign: 'center', mx: {xs: 2, md: 6}}}>
                        <Typography 
                            sx={{ 
                                color: theme.palette.main.complementary_dark, 
                                py: {xs: 1, md: 2},
                                fontSize: {xs: '1.2em', md: '2em'}, 
                                fontWeight: 'bold'
                            }}
                        >
                            HEATING AND COOLING SOLUTIONS YEAR-ROUND
                        </Typography>
                    </Card>
                </Box>
                <Box sx={{ py: 3, textAlign: 'center'}}>
                    <Stack spacing={1}>
                        <Typography >Our homes in the Pacific Northwest are subject to various weather conditions, and we are prepared to provide you the services required to keep your home comfortable. With our reliable professionals utilizing high quality equipment, we guarantee your satisfaction. Call Today!</Typography>
                    </Stack>
                </Box>
            </Container>

            <Grid container spacing={2} sx={{my: 2, textAlign: 'center'}}>
                <Grid item xs={12} md={4}>
                    <Card sx={{backgroundImage: "linear-gradient(#701617, #8A1818, #CC2929)", height: '100%', color: 'white', px: 2}}>
                        <Box className='row justify-content-center py-5'>
                            <img className="toolbox " src={toolbox} style={{height: '70px'}} alt=""></img>
                        </Box>
                        <Box className='row pb-5'>
                            <Typography variant='h5' fontWeight={'bold'}>Maintenance</Typography>
                            <Typography variant='body2'>The proper operation of our home’s heating and air conditioning systems require annual maintenance and inspection to remain reliable. We are able to provide our customers with a comprehensive assessment of their home’s HVAC systems and keep them running smoothly for years to come.</Typography>
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card sx={{backgroundImage: "linear-gradient(#701617, #8A1818, #CC2929)", height: '100%', color: 'white', px: 2}}>
                        <Box className='row justify-content-center py-5'>
                            <img className="retrofit" src={retrofit} style={{height: '70px'}} alt=""></img>
                        </Box>
                        <Box className='row pb-5'>
                            <Typography variant='h5' fontWeight={'bold'}>Retrofit</Typography>
                            <Typography variant='body2'>No matter how well a system was installed there will come a time that it will be in need of replacement. We will be able to appropriately size a replacement system for you and modify your existing system to ensure proper long-term performance.</Typography>
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card sx={{backgroundImage: "linear-gradient(#701617, #8A1818, #CC2929)", height: '100%', color: 'white', px: 2}}>
                        <Box className='row justify-content-center py-5'>
                            <img className="fan" src={fan} style={{height: '70px'}} alt=""></img>
                        </Box>
                        <Box className='row pb-5'>
                            <Typography variant='h5' fontWeight={'bold'}>New Installation</Typography>
                            <Typography variant='body2'>Are you considering converting your home’s HVAC system? Do the summers seem hotter than before? No matter your situation, we are here to help and can offer you modern comfort solutions.</Typography>
                        </Box>
                
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )

};

export default HVAC
