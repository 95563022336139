import './PlumbingCommercial.css';
import maintenance from "../Images/maintenance-icon.svg";
import tenant from "../Images/tenant-icon.svg";
import genPlumbing from "../Images/gen-plumbing-icon.svg";
import vans from "../Images/vans.png";

import { useEffect } from 'react';
import seo from '../Lib/Seo.js';

// MUI imports
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack'; 
import Grid from '@mui/material/Grid';
import { useTheme } from '@emotion/react';

function PlumbingCommercial() {
    const theme = useTheme();

    useEffect(() => {
        seo.setMetaTitle("Commercial Plumbing Services in Seattle | A-Game");
        seo.setMetaDescription("When you need commercial plumbing service in Seattle, A-Game Plumbing and Heating is your unparalleled choice. We handle all jobs, whether big or small.");

        return () => {
            seo.unsetCustomMetadata();
        };
    });

    return (
        <Box>
            <Container maxWidth='xl' disableGutters>
                <Box sx={{ 
                    backgroundImage: `url(${vans})`
                    , backgroundRepeat: 'no-repeat'
                    , backgroundSize: "contain"
                    , height: {xs: '250px', md: '500px'}
                    , display: 'flex'
                    , justifyContent: {xs: 'right', md: 'center'}
                    , mt: {xs: 3, md: 4}
                    
                }}>
                    <Box sx={{ maxWidth: {xs: '50%', md: '40%'}, ml: {xs: 0, md: 50}, pt: 3, mr: 1}}>
                        <Card sx={{bgcolor: theme.palette.main.primary_lightest}}>
                            <CardContent>
                                <Typography gutterBottom textAlign='center'
                                    sx={{
                                        fontSize: {xs: '.95em', md: '2em'},
                                        color: theme.palette.main.complementary_dark,
                                        fontWeight: 'bold',
                                    }}>COMMERCIAL PLUMBING
                                </Typography>
                                <Typography textAlign='center' gutterBottom sx={{ fontSize: {xs: '0em', md: '1.5em'}}}>
                                    Local plumbers with over 50 years of experience.
                                </Typography>
                            </CardContent>
                        </Card>
                      
                    </Box>
                </Box>
            </Container>

            <Container maxWidth='xl' sx={{ bgcolor: theme.palette.main.complementary_lightest}}>
                <Box sx={{ display: 'flex', pt: 3, justifyContent: 'center' }}>
                    <Card sx={{ bgcolor: theme.palette.main.primary_lightest, width: {xs: '100%', md: '50%'}, textAlign: 'center', mx: {xs: 2, md: 6}}}>
                        <Typography 
                            sx={{ 
                                color: theme.palette.main.complementary_dark, 
                                py: {xs: 1, md: 2},
                                fontSize: {xs: '1.2em', md: '2em'}, 
                                fontWeight: 'bold'
                            }}
                        >
                            COMMERCIAL PLUMBING COVERED
                        </Typography>
                    </Card>
                </Box>
                <Box sx={{ py: 3, textAlign: 'center'}}>
                    <Stack spacing={1}>
                        <Typography>
                            When it comes to ensuring that your plumbing systems runs 
                            seamlessly, A-Game Plumbing and Heating is your unparalleled choice. 
                            We know how crucial uninterrupted operations are to you, which is 
                            why we bring our A-Game to every commercial plumbing service in 
                            Seattle. We can even take care of minor commercial drain repairs. 
                            No matter the job, our expert team of licensed professionals is 
                            equipped with the latest technology to deliver efficient, reliable 
                            services that keep your business moving without a hitch.
                        </Typography>
                        <Typography>From routine plumbing maintenance to emergency repairs, 
                            we're dedicated to providing prompt, top-notch service that meets 
                            your unique needs. Trust A-Game Plumbing and Heating to be your 
                            steadfast partner so that you can focus more on what you do best. 
                            Reach out today to experience the difference our commitment to 
                            excellence and customer satisfaction can make for your business. 
                            Learn more about our commercial plumbing services in Seattle below.
                        </Typography>
                    </Stack>
                </Box>
            </Container>
            
            <Grid container spacing={2} sx={{my: 2, textAlign: 'center'}}>
                <Grid item xs={12} md={4}>
                    <Card sx={{backgroundImage: "linear-gradient(#00467F, #1382C7)", height: '100%', color: 'white', px: 2}}>
                        <Box className='row justify-content-center py-5'>
                            <img className="maintenance " src={maintenance} style={{height: '70px'}} alt=""></img>
                        </Box>
                        <Box className='row pb-5'>
                            <Typography variant='h5' fontWeight={'bold'}>Routine Maintenance</Typography>
                            <Typography variant='body2'>We understand that our commercial customers in the greater Seattle area have a more significant need for routine maintenance in their facilities. We offer drain cleaning, scoping for inspection, and service plans that will fit your needs and your budget while assisting you in identifying issues before they arise.</Typography>
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card sx={{backgroundImage: "linear-gradient(#00467F, #1382C7)", height: '100%', color: 'white', px: 2}}>
                        <Box className='row justify-content-center py-5'>
                            <img className="tenant" src={tenant} style={{height: '70px'}} alt=""></img>
                        </Box>
                        <Box className='row pb-5'>
                            <Typography variant='h5' fontWeight={'bold'}>Tenant Improvement</Typography>
                            <Typography variant='body2'>Tenant Improvement New commercial customers often require renovation of their space to conduct Business. At A-Game Plumbing and Heating, we are prepared and able to design and install plumbing systems for your business in a timely manner and keep your down-time to a minimum.</Typography>
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card sx={{backgroundImage: "linear-gradient(#00467F, #1382C7)", height: '100%', color: 'white', px: 2}}>
                        <Box className='row justify-content-center py-5'>
                            <img className="genPlumbing" src={genPlumbing} style={{height: '70px'}} alt=""></img>
                        </Box>
                        <Box className='row pb-5'>
                            <Typography variant='h5' fontWeight={'bold'}>General Plumbing</Typography>
                            <Typography variant='body2'>Let's face it, nobody knows when plumbing problems will occur and to a business, the down time caused by these issues can be quite an inconvenience.  Any repair or service options available in a residential capacity are offered for commercial properties as well from leaking pipes to clogged drains and new fixture installs.</Typography>
                        </Box>
                
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
};

export default PlumbingCommercial