import './App.css';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import Home from './Pages/Home';
import HVAC from './Pages/HVAC';
import Heating from './Pages/Heating';
import AirConditioning from './Pages/AirConditioning';
import About from './Pages/About';
// import NotFound from './Pages/NotFound'
import PlumbingResidential from './Pages/PlumbingResidential';
import PlumbingCommercial from './Pages/PlumbingCommercial';
import { Route, Routes } from "react-router-dom";

import { ThemeProvider, createTheme } from '@mui/material/styles';
 
const theme = createTheme({
  palette: {
    main: {
      primary: '#44BBFF'
      , primary_light: '#b7e7ff'
      , primary_lightest: '#e3f6ff'
      , primary_dark: '#338cd9'
      , primary_darkest: '#295ba1'
      // , complementary: '#ffc654'
      // , complementary_dark: '#ffaf4e'
      // , complementary_darkest: '#ff8944'
      // , complementary_light: '#fff4a5'
      // , complementary_lightest: '#fffeea'
      , complementary: '#f34342'
      , complementary_dark: '#E91D25'
      , complementary_darkest: '#bc0006'
      , complementary_light: '#f39493'
      , complementary_lightest: '#ffeaed'
      , analogous: '#445dff'
      , analogous_dark: '#0025e2'
      , analogous_darkest: '#0000be'
      , analogous_light: '#c6c6ff'
      , analogous_lightest: '#e8e9ff'
      , lightgray: '#f2f4f4'
    }
  }
})
function App() {

  return (
   
    <ThemeProvider theme={theme}>
      <Navbar></Navbar>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/plumbing-residential' element={<PlumbingResidential />} />
        <Route path='/plumbing-commercial' element={<PlumbingCommercial />} />
        <Route path='/about' element={<About />} />
        <Route path='/hvac' element={<HVAC />} />
        <Route path='/heating' element={<Heating />} />
        <Route path='/air-conditioning' element={<AirConditioning />} />
        {/* <Route path="*" element={<NotFound/>}/> */}
      </Routes>
      <Footer></Footer>
    </ThemeProvider>
    
  );
}


export default App;
