import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import quoteIcon from "../../Images/quote-icon.svg";
import stars from "../../Images/5star.png";

export default function DeanH({stringAvatar}) {


    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', m: 2}}>
            <Box component="img"
                alt="quote"
                src={quoteIcon}
                sx={{ borderRadius: 3, py: {xs: 1, md: 2}, maxWidth: '70px'}}
            />
            <Box component="img"
                alt="five stars"
                src={stars}
                sx={{ mx: 'auto', borderRadius: 3, py: {xs: 1, md: 2}, maxWidth: '200px'}}
            />
            <Typography sx={{ minHeight: {xs: '264px', md: '144px'} }}>
                I had laundry room work. They relocated my washer drain and supply, removed 
                old galvanized pipe, and plumbed for a new washroom sink. Prompt, reliable, 
                good communicators and did great work. Will be using them for all my future 
                plumbing needs.
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 1}}>
                <Avatar {...stringAvatar('Dean H.')} />
            </Box>
            <Typography sx={{ textAlign: 'center'}}>Dean H.</Typography> 
        </Box>
    )
}