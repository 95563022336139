import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import quoteIcon from "../../Images/quote-icon.svg";
import stars from "../../Images/5star.png";


export default function MollyD({stringAvatar}) {


    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', m: 2}}>
            <Box component="img"
                alt="quote"
                src={quoteIcon}
                sx={{ borderRadius: 3, py: {xs: 1, md: 2}, maxWidth: '70px'}}
            />
            <Box component="img"
                alt="five stars"
                src={stars}
                sx={{ mx: 'auto', borderRadius: 3, py: {xs: 1, md: 2}, maxWidth: '200px'}}
            />
            <Typography sx={{ minHeight: {xs: '264px', md: '144px'} }}>
                I highly recommend A-game plumbing! Lance and his crew are courteous, 
                professional and quick. They went above and beyond fitting us in quickly 
                in serious emergency situations. I completely trust them to give an 
                honest opinion. They definitely tried to fix our situation with the most 
                affordable, yet effective way possible. Shop no further. You found your 
                plumber.
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 1}}>
                <Avatar {...stringAvatar('Molly D.')} />
            </Box>
            <Typography sx={{ textAlign: 'center'}}>Molly D.</Typography> 
        </Box>
    )
}