/**
 * @author Logical Position
 * 
 * To use:
 * Import the needed functions and call the `set` functions within a 
 * `useEffect` call. Call the `unset` function within the return callback:
 *
 *   import seo from '../Lib/Seo.js';
 * 
 *   useEffect(() => {
 *       seo.setMetaTitle('custom title');
 *       seo.setMetaDescription('custom description');
 *       return () => {
 *           seo.unsetCustomMetadata();
 *       }
 *  });
 */

let defaultTitle = "";
let defaultDescription = "";

const setMetaTitle = (title) => {
    const el = document.head.querySelector('title');
    if (el) {
      defaultTitle = el.innerText;
      el.innerText = title;
    } else {
      // create title and add to page
      const titleEl = document.createElement('title');
      titleEl.innerText = title;
      document.head.append(titleEl);
    }
}

const setMetaDescription = (desc) => {
    const el = document.head.querySelector('meta[name="description"]');
    if (el) {
      defaultDescription = el.getAttribute('content');
      el.setAttribute('content', desc);
    } else {
      // create desc and add to page
      const descEl = document.createElement('meta');
      descEl.setAttribute('name', 'description');
      descEl.setAttribute('content', desc);
      document.head.append(descEl);
    }
}

const unsetCustomMetadata = () => {
    const titleEl = document.head.querySelector('title');
    if (titleEl) {
      titleEl.innerText = defaultTitle;
    }

    const descEl = document.head.querySelector('meta[name="description"]');
    if (descEl) {
      descEl.setAttribute('content', defaultDescription);
    }
}

const seo = { setMetaTitle, setMetaDescription, unsetCustomMetadata }
export default seo;